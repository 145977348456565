exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-erbjudanden-js": () => import("./../../../src/pages/erbjudanden.js" /* webpackChunkName: "component---src-pages-erbjudanden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-studentflak-js": () => import("./../../../src/pages/studentflak.js" /* webpackChunkName: "component---src-pages-studentflak-js" */),
  "component---src-pages-studentguide-js": () => import("./../../../src/pages/studentguide.js" /* webpackChunkName: "component---src-pages-studentguide-js" */),
  "component---src-pages-studentklader-js": () => import("./../../../src/pages/studentklader.js" /* webpackChunkName: "component---src-pages-studentklader-js" */),
  "component---src-pages-studentmossa-js": () => import("./../../../src/pages/studentmossa.js" /* webpackChunkName: "component---src-pages-studentmossa-js" */),
  "component---src-pages-studentpresent-js": () => import("./../../../src/pages/studentpresent.js" /* webpackChunkName: "component---src-pages-studentpresent-js" */),
  "component---src-pages-studentskylt-js": () => import("./../../../src/pages/studentskylt.js" /* webpackChunkName: "component---src-pages-studentskylt-js" */)
}

